import { queryData } from '@/domains/core/graphqlClient/queryClient';
import { getPlatform, isB2B } from '@/domains/core/settings/utils';
import type { UserSegments } from '@/domains/productDiscovery/CommercialAnimation/interfaces/bannerCampaign';
import { HomepageTertiaryCampaignsDocument } from '@/productDiscovery/CommercialAnimation/services/graphql/BannerCampaigns/generated/bannerCampaigns.query.generated';

export const fetchHomepageTertiaryCampaignsGql = (
  userSegments?: UserSegments,
) =>
  queryData(HomepageTertiaryCampaignsDocument, {
    platform: getPlatform().toUpperCase(),
    market: isB2B() ? 'B2B' : 'B2C',
    userSegments: userSegments || {},
  });
