import { forwardRef } from 'react';

import {
  GenericButton,
  type GenericButtonProps,
} from '@/core/tamagoshiv2/components/Button/GenericButton';

export type ButtonProps = GenericButtonProps<'button'>;

/**
 * A general-purpose button implementing the Design System.
 *
 * If you actually need a link, use `NavigationButton` for a button appearance
 * but with link semantics. Your label should be short and use the imperative
 * tone. If the action behind the button takes time, use the loading mechanism.
 *
 * See the related [Figma][figma] for more details.
 *
 * [figma]: https://www.figma.com/file/KAiRlBccr6bFYmKby9wQrR?node-id=11045-34426
 */
export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (props, ref) => <GenericButton asComponent="button" ref={ref} {...props} />,
);

Button.displayName = 'Button';
