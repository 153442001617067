import { isB2C } from '@/core/settings/utils';
import { merchandisingLabels } from '@/core/tamagoshi/MerchandisingTag/interfaces';
import type { ProductModel } from '@/domains/retention/Homepage/b2c/interfaces/state.model';

export const GTM_DISPLAY_PLAYLIST = (
  playlistType: string,
  hasPromoProduct: boolean,
  currency?: string,
) => ({
  event: 'interaction_display playlist',
  e_category: 'ecommerce',
  e_action: 'Display playlist',
  e_label: playlistType,
  amp_event_name: 'Display playlist',
  event_trigger: isB2C() ? 'homepage b2c' : 'homepage b2b',
  block_clicked: playlistType,
  interaction_hit: 'True',
  currency,
  has_promo_product: hasPromoProduct,
});

const productsTrackingData = (products: ProductModel[]) =>
  products.map((product: ProductModel, position: number) => ({
    list_name: undefined,
    pr_id: product.articleId,
    pr_product_id: product.productId,
    pr_article_id: product.articleId,
    pr_name: product.title,
    pr_stock: undefined,
    pr_position: position + 1,
    pr_price: product.price,
    pr_base_price: product.price,
    pr_brand: product.brand.name,
    pr_brand_id: product.brand.id,
    pr_model_name: product.title,
    pr_model_id: product.modelId,
    pr_rating: product.rating.average,
    pr_rating_count: product.rating.count,
    pr_is_restocking: undefined,
    pr_is_sponsored: undefined,
    pr_is_mf: undefined,
    pr_is_top_sales: undefined,
    pr_is_best_seller: undefined,
    pr_is_pro: undefined,
    pr_has_promo_label: Boolean(
      product.promoTags?.includes(merchandisingLabels.PROMO),
    ),
    pr_has_coupon_label: !!product.couponAmount,
    pr_is_cpt: product.isCompetitive,
  }));

export const GTM_DISPLAY_PLAYLIST_WITH_PRODUCTS = (
  playlistType: string,
  hasPromoProduct: boolean,
  playlistName: string,
  pageType: string,
  products: ProductModel[],
  currency?: string,
) => ({
  ...GTM_DISPLAY_PLAYLIST(playlistType, hasPromoProduct, currency),
  playlist_name: playlistName,
  page_type: pageType,
  pr_products: productsTrackingData(products),
});
