import classnames from 'classnames';
import { useCallback, type FunctionComponent } from 'react';

import { Image, type ImageProps } from '@/core/Image/Image';
import { Link } from '@/core/routing/components/Link';
import { isB2B, isGermanyPlatform } from '@/core/settings/utils';
import { merchandisingLabels } from '@/core/tamagoshi/MerchandisingTag/interfaces';
import type {
  Merchandising,
  ProductCardTranslations,
} from '@/core/tamagoshi/ProductCard/interfaces';
import {
  ProductCard as ProductCardTamagoshi,
  type ProductCardProps,
} from '@/core/tamagoshi/ProductCard/ProductCard';
import { Gtm } from '@/core/tracking/utils/Gtm';
import { useBooleanFlags } from '@/domains/core/flags/flags.hooks';
import { ABTests } from '@/domains/proB2BAnimation/abtests';
import { getDelivery } from '@/domains/productDiscovery/ProductCard/utils/getDelivery';
import { productHasDiscount } from '@/productDiscovery/CommercialAnimation/utils/getProductPromoTags';
import { getProductCardTranslations } from '@/productDiscovery/ProductCard/translations';
import { mapCurrency } from '@/productDiscovery/ProductCard/utils/mapCurrency';

import type { ProductModel } from '../../b2c/interfaces/state.model';

import styles from './HomepageProductCard.module.scss';

interface Props {
  className?: string;
  product: ProductModel;
  // Used for tracking purpose
  position: number;
  recommendationEngineName?: string;
  eventTrigger?: string;
  blockClicked?: string;
  spaceSlug?: string;
  interactionHit?: string;
  loading?: 'eager' | 'lazy';
  listType?: string;
}

export const HomepageProductCard: FunctionComponent<Props> = ({
  position,
  product,
  recommendationEngineName,
  eventTrigger,
  blockClicked,
  spaceSlug,
  interactionHit,
  loading = 'lazy',
  className,
  listType,
}) => {
  const { modelCount, couponAmount } = product;
  const productCardTranslations: ProductCardTranslations =
    getProductCardTranslations(modelCount, couponAmount || undefined, false, {
      adPayer: 'BRAND',
      sellerName: product.advertiser || '',
      brandName: product.sponsor || '',
    });
  const [enableMfRebranding] = useBooleanFlags([ABTests.ENABLE_MF_REBRANDING]);

  const price = product.price.priceWithVat;
  const retailPrice =
    product.price.retailPriceWithVat &&
    product.price.retailPriceWithVat > product.price.priceWithVat
      ? product.price.retailPriceWithVat
      : 0;

  const trackProductCard = useCallback(async () => {
    const { computeDefaultTrackingData } = await import(
      '@/retention/Homepage/components/HomepageProductCard/tracking'
    );
    const defaultTrackingData = computeDefaultTrackingData(
      position,
      product,
      price,
      false,
    );
    const extendedTracking = {
      ...defaultTrackingData,
      event_trigger: eventTrigger,
      block_clicked: blockClicked,
      space_slug: spaceSlug,
      interaction_hit: interactionHit,
      pr_products: [
        {
          ...defaultTrackingData.pr_products[0],
          reco_engine_name: recommendationEngineName,
        },
      ],
    };
    Gtm.push(extendedTracking);
  }, [
    position,
    product,
    price,
    recommendationEngineName,
    eventTrigger,
    blockClicked,
    spaceSlug,
    interactionHit,
  ]);

  const imageComponent = useCallback(
    (props: ImageProps) => (
      <Image
        {...props}
        data-testid={`${loading}-image`}
        loading={loading as 'eager' | 'lazy'}
      />
    ),
    [loading],
  );

  const merchandising = () => {
    if (product.advertiser && product.sponsor) {
      return ['sponsored'] as Merchandising[];
    }

    if (couponAmount) {
      return [merchandisingLabels.COUPON] as Merchandising[];
    }

    return product.promoTags &&
      product.promoTags.length > 0 &&
      (productHasDiscount(price, retailPrice) ||
        product.promoTags.includes(merchandisingLabels.LOWERED_PRICE))
      ? (product.promoTags as Merchandising[])
      : undefined;
  };

  return (
    <ProductCardTamagoshi
      tag={Link}
      tagProps={{
        href: product.path,
      }}
      className={classnames(className, {
        [styles.playlistProductCard]: enableMfRebranding,
      })}
      title={product.title}
      currency={mapCurrency(product.price.currency)}
      price={price}
      retailPrice={retailPrice}
      imageSrc={product.imageUrl}
      brandImageSrc={product.brand.imageUrl}
      brandName={product.brand.name}
      modelsCount={modelCount}
      rating={product.rating.average}
      ratingCount={product.rating.count}
      legalMention={isGermanyPlatform()}
      b2b={isB2B()}
      tax={isB2B() ? 'withoutVAT' : undefined}
      vertical
      translations={productCardTranslations}
      onClick={trackProductCard}
      imageComponent={imageComponent as ProductCardProps['imageComponent']}
      light
      merchandising={merchandising()}
      displayDeliveryLabel
      delivery={getDelivery(product)}
    />
  );
};

HomepageProductCard.displayName = 'HomepageProductCard';
