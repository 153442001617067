import { BannerCampaignAdvertiserLabel } from '@/domains/productDiscovery/CommercialAnimation/components/AdvertiserInformation/BannerCampaignAdvertiserLabel';
import { CampaignImage } from '@/productDiscovery/CommercialAnimation/components/CampaignImage/CampaignImage';
import { useHomepageSecondaryCampaigns } from '@/retention/Homepage/b2c/hooks/useHomepageSecondaryCampaigns';
import { PartnerAdBanner } from '@/sellerAdsManagement/PartnerAdBannerCampaign/components/PartnerAdBanner';
import { spaceSlugType } from '@/sellerAdsManagement/PartnerAdBannerCampaign/interfaces/spaceSlugTypes';

import styles from './HomepageSecondaryCampaigns.module.scss';

const SIZES = {
  small: {
    ldpi: {
      width: 280,
      height: 100,
    },
    hdpi: {
      width: 540,
      height: 200,
    },
  },
  medium: {
    ldpi: {
      width: 480,
      height: 100,
    },
    hdpi: {
      width: 960,
      height: 200,
    },
  },
  large: {
    ldpi: {
      width: 680,
      height: 142,
    },
    hdpi: {
      width: 1360,
      height: 284,
    },
  },
};

export const HomepageSecondaryCampaigns = () => {
  const { secondaryCampaigns, fetched } = useHomepageSecondaryCampaigns();

  if (!fetched && (!secondaryCampaigns || secondaryCampaigns.length < 1)) {
    return <div className={styles.skeleton} />;
  }

  if (!secondaryCampaigns || secondaryCampaigns.length < 1) {
    return null;
  }

  return (
    <div className={styles.homepageSecondaryCampaigns}>
      {secondaryCampaigns[0] &&
        secondaryCampaigns[0].image &&
        secondaryCampaigns[0].link &&
        (!secondaryCampaigns[0].isPartnerAd ? (
          <div className={styles.campaignContainer}>
            {(secondaryCampaigns[0].sponsor ||
              secondaryCampaigns[0].advertiser) && (
              <BannerCampaignAdvertiserLabel
                sponsor={secondaryCampaigns[0].sponsor}
                advertiser={secondaryCampaigns[0].advertiser}
                userSegments={secondaryCampaigns[0].userSegments}
                spaceSlug="secondary_campaigns"
              />
            )}
            <CampaignImage
              sizes={SIZES}
              altText={secondaryCampaigns[0].image.altText}
              imageMobileUrl={secondaryCampaigns[0].image.mobileUrl || ''}
              imageDesktopUrl={secondaryCampaigns[0].image.desktopUrl || ''}
              imgClassName={styles.homepageSecondaryCampaign}
              dataTestId="HomepageSecondary"
              link={secondaryCampaigns[0].link}
              type="secondary"
              bannerCampaign={secondaryCampaigns[0]}
              position={0}
            />
          </div>
        ) : (
          <PartnerAdBanner
            partnerAdBannerCampaign={secondaryCampaigns[0]}
            bannerTypeTracking="secondary-banner"
            spaceSlug={spaceSlugType.BRAND_BLOCK}
            containerClassName={styles.campaignContainer}
          />
        ))}
      {secondaryCampaigns[1] &&
        secondaryCampaigns[1].image &&
        secondaryCampaigns[1].image &&
        (!secondaryCampaigns[1].isPartnerAd ? (
          <div className={styles.campaignContainer}>
            {(secondaryCampaigns[1].sponsor ||
              secondaryCampaigns[1].advertiser) && (
              <BannerCampaignAdvertiserLabel
                sponsor={secondaryCampaigns[1].sponsor}
                advertiser={secondaryCampaigns[1].advertiser}
                userSegments={secondaryCampaigns[1].userSegments}
                spaceSlug="secondary_campaigns"
              />
            )}

            <CampaignImage
              sizes={SIZES}
              altText={secondaryCampaigns[1].image.altText}
              imageMobileUrl={secondaryCampaigns[1].image.mobileUrl || ''}
              imageDesktopUrl={secondaryCampaigns[1].image.desktopUrl || ''}
              imgClassName={styles.homepageSecondaryCampaign}
              dataTestId="HomepageSecondary"
              link={secondaryCampaigns[1].link}
              type="secondary"
              bannerCampaign={secondaryCampaigns[1]}
              position={1}
            />
          </div>
        ) : (
          <PartnerAdBanner
            partnerAdBannerCampaign={secondaryCampaigns[1]}
            bannerTypeTracking="secondary-banner"
            spaceSlug={spaceSlugType.BRAND_BLOCK}
            containerClassName={styles.campaignContainer}
          />
        ))}
    </div>
  );
};
