import type { Hit, SearchOptions } from '@algolia/client-search';
import { useEffect, useState } from 'react';

import { useVisitorId } from '@/domains/core/tracking/hooks/useVisitorId';
import { useAuth } from '@/domains/customerManagement/auth/hooks/useAuth';
import { AlgoliaSearchInsights } from '@/domains/productDiscovery/algolia/searchInsights/AlgoliaSearchInsights';
import type { ProductDocument } from '@/productDiscovery/algolia/interfaces/hit';
import type { Product } from '@/productDiscovery/algolia/interfaces/product';
import { hitToProduct } from '@/productDiscovery/algolia/productProcessor';
import { useRecommendationsFromProductHistory } from '@/productDiscovery/Recommendation/modules/ProductHistoryRecommendation/hooks/useRecommendationsFromProductHistory';
import { search } from '@/productDiscovery/Recommendation/services/algolia/proxy.client';

const buildSearchParameters = (modelIds: number[] = []): SearchOptions => ({
  facetFilters: [modelIds.map((id) => `model_id:${id}`)],
});

type RecommendationEngine = Parameters<
  typeof useRecommendationsFromProductHistory
>[0];

export const useRecentlyViewedProductsRecommendations = (
  recommendationEngine: RecommendationEngine,
): Product[] | null => {
  const [recommendationProducts, setRecommendationProducts] = useState<
    Product[] | null
  >(null);
  const [algoliaProducts, setAlgoliaProducts] = useState<
    Hit<ProductDocument>[]
  >([]);

  const recommendationsIds: number[] =
    useRecommendationsFromProductHistory(recommendationEngine);

  const { userId } = useAuth();
  const { visitorId } = useVisitorId();

  useEffect(() => {
    if (
      recommendationEngine === 'FrequentlyBoughtTogether' &&
      !recommendationProducts &&
      recommendationsIds &&
      recommendationsIds.length > 0
    ) {
      const searchParameters = buildSearchParameters(recommendationsIds);

      const fetchProducts = async () => {
        const userToken = userId
          ? await AlgoliaSearchInsights.calculateUserIdHash(String(userId))
          : visitorId;

        const data = await search(searchParameters, userToken).then(
          (result) => result,
        );

        setAlgoliaProducts(data[0].hits);
      };

      fetchProducts();
    }
  }, [
    recommendationEngine,
    recommendationProducts,
    recommendationsIds,
    userId,
    visitorId,
  ]);

  useEffect(() => {
    if (algoliaProducts && algoliaProducts.length > 0) {
      const processedProducts = algoliaProducts.map((hit) => hitToProduct(hit));
      setRecommendationProducts(processedProducts);
    }
  }, [algoliaProducts]);

  return recommendationProducts;
};
