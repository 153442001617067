import type { BannerCampaign } from '@/domains/productDiscovery/CommercialAnimation/interfaces/bannerCampaign';
import type {
  HomepageServerModel,
  ProductModel,
} from '@/domains/retention/Homepage/b2c/interfaces/state.model';
import type { HomepageCampaignsModel } from '@/domains/retention/Homepage/interfaces/homepageCampaigns.model';
import { Product } from '@/productDiscovery/algolia/interfaces/product';
import type { CommercialOperationMetadata } from '@/productDiscovery/Listings/commercialOperation/model';

export const GET_HOMEPAGE = 'retention/GET_HOMEPAGE';
export const GET_HOMEPAGE_B2B = 'retention/GET_HOMEPAGE_B2B';
export const GET_HOMEPAGE_SUCCESS = 'retention/GET_HOMEPAGE_SUCCESS';
export const GET_HOMEPAGE_FAILURE = 'retention/GET_HOMEPAGE_FAILURE';
export const GET_HOMEPAGE_CAMPAIGNS = 'retention/GET_HOMEPAGE_CAMPAIGNS';
export const GET_HOMEPAGE_CAMPAIGNS_SUCCESS =
  'retention/GET_HOMEPAGE_CAMPAIGNS_SUCCESS';
export const GET_HOMEPAGE_CAMPAIGNS_FAILURE =
  'retention/GET_HOMEPAGE_CAMPAIGNS_FAILURE';
export const FETCH_SECONDARY_PROMOTIONS =
  'retention/FETCH_SECONDARY_PROMOTIONS';
export const FETCH_SECONDARY_PROMOTIONS_SUCCESS =
  'retention/FETCH_SECONDARY_PROMOTIONS_SUCCESS';
export const FETCH_SECONDARY_PROMOTIONS_FAILURE =
  'retention/FETCH_SECONDARY_PROMOTIONS_FAILURE';
export const GET_HOMEPAGE_SECONDARY_CAMPAIGNS =
  'retention/GET_HOMEPAGE_SECONDARY_CAMPAIGNS';
export const GET_HOMEPAGE_SECONDARY_CAMPAIGNS_SUCCESS =
  'retention/GET_HOMEPAGE_SECONDARY_CAMPAIGNS_SUCCESS';
export const GET_HOMEPAGE_SECONDARY_CAMPAIGNS_FAILURE =
  'retention/GET_HOMEPAGE_SECONDARY_CAMPAIGNS_FAILURE';
export const GET_HOMEPAGE_TERTIARY_CAMPAIGNS =
  'retention/GET_HOMEPAGE_TERTIARY_CAMPAIGNS';
export const GET_HOMEPAGE_TERTIARY_CAMPAIGNS_SUCCESS =
  'retention/GET_HOMEPAGE_TERTIARY_CAMPAIGNS_SUCCESS';
export const GET_HOMEPAGE_TERTIARY_CAMPAIGNS_FAILURE =
  'retention/GET_HOMEPAGE_TERTIARY_CAMPAIGNS_FAILURE';
export const GET_SUGGESTED_PRODUCTS_SUCCESS =
  'retention/GET_SUGGESTED_PRODUCTS_SUCCESS';
export const GET_PROMOTED_PRODUCTS_SUCCESS =
  'retention/GET_PROMOTED_PRODUCTS_SUCCESS';
export const GET_HOMEPAGE_COMMERCIAL_OPERATION_BANNER_DATA =
  'retention/GET_HOMEPAGE_COMMERCIAL_OPERATION_BANNER_DATA';
export const GET_HOMEPAGE_COMMERCIAL_OPERATION_BANNER_DATA_SUCCESS =
  'retention/GET_HOMEPAGE_COMMERCIAL_OPERATION_BANNER_DATA_SUCCESS';
export const GET_HOMEPAGE_COMMERCIAL_OPERATION_BANNER_DATA_FAILURE =
  'retention/GET_HOMEPAGE_COMMERCIAL_OPERATION_BANNER_DATA_FAILURE';

export interface GetHomepage {
  type: typeof GET_HOMEPAGE;
}

export interface GetHomepageB2B {
  type: typeof GET_HOMEPAGE_B2B;
}

export const getHomepageB2B = (): GetHomepageB2B => ({
  type: GET_HOMEPAGE_B2B,
});

export interface GetHomepageSuccess {
  type: typeof GET_HOMEPAGE_SUCCESS;
  data: HomepageServerModel;
}

export interface GetHomepageFailure {
  type: typeof GET_HOMEPAGE_FAILURE;
  error: string;
}

export interface GetHomepageCampaigns {
  type: typeof GET_HOMEPAGE_CAMPAIGNS;
}

export interface GetHomepageCampaignsSuccess {
  type: typeof GET_HOMEPAGE_CAMPAIGNS_SUCCESS;
  data: HomepageCampaignsModel;
}

export interface GetHomepageCampaignsFailure {
  type: typeof GET_HOMEPAGE_CAMPAIGNS_FAILURE;
  error: string;
}

export interface GetSuggestedProductsSuccess {
  type: typeof GET_SUGGESTED_PRODUCTS_SUCCESS;
  data: ProductModel[];
}

export interface GetPromotedProductsSuccess {
  type: typeof GET_PROMOTED_PRODUCTS_SUCCESS;
  data: ProductModel[];
}

export interface FetchSecondaryPromotions {
  type: typeof FETCH_SECONDARY_PROMOTIONS;
}

export const fetchSecondaryPromotions = (): FetchSecondaryPromotions => ({
  type: FETCH_SECONDARY_PROMOTIONS,
});

export interface FetchSecondaryPromotionsSuccess {
  type: typeof FETCH_SECONDARY_PROMOTIONS_SUCCESS;
  data: BannerCampaign[];
}

export const fetchSecondaryPromotionsSuccess = (
  data: BannerCampaign[],
): FetchSecondaryPromotionsSuccess => ({
  type: FETCH_SECONDARY_PROMOTIONS_SUCCESS,
  data,
});

export interface FetchSecondaryPromotionsFailure {
  type: typeof FETCH_SECONDARY_PROMOTIONS_FAILURE;
  error: string;
}

export const fetchSecondaryPromotionsFailure = (
  error: string,
): FetchSecondaryPromotionsFailure => ({
  type: FETCH_SECONDARY_PROMOTIONS_FAILURE,
  error,
});
export interface GetHomepageSecondaryCampaigns {
  type: typeof GET_HOMEPAGE_SECONDARY_CAMPAIGNS;
}

export const getHomepageSecondaryCampaigns =
  (): GetHomepageSecondaryCampaigns => ({
    type: GET_HOMEPAGE_SECONDARY_CAMPAIGNS,
  });

export interface GetHomepageSecondaryCampaignsSuccess {
  type: typeof GET_HOMEPAGE_SECONDARY_CAMPAIGNS_SUCCESS;
  data: BannerCampaign[] | null;
}

export interface GetHomepageSecondaryCampaignsFailure {
  type: typeof GET_HOMEPAGE_SECONDARY_CAMPAIGNS_FAILURE;
  error: string;
}

export interface GetHomepageTertiaryCampaigns {
  type: typeof GET_HOMEPAGE_TERTIARY_CAMPAIGNS;
}

export const getHomepageTertiaryCampaigns =
  (): GetHomepageTertiaryCampaigns => ({
    type: GET_HOMEPAGE_TERTIARY_CAMPAIGNS,
  });

export interface GetHomepageTertiaryCampaignsSuccess {
  type: typeof GET_HOMEPAGE_TERTIARY_CAMPAIGNS_SUCCESS;
  data: BannerCampaign[] | null;
}

export interface GetHomepageTertiaryCampaignsFailure {
  type: typeof GET_HOMEPAGE_TERTIARY_CAMPAIGNS_FAILURE;
  error: string;
}

export interface GetHomepageCommercialOperationBannerData {
  type: typeof GET_HOMEPAGE_COMMERCIAL_OPERATION_BANNER_DATA;
  coId: number;
  bannerId: string;
}

export const getHomepageCommercialOperationBannerData = (
  coId: number,
  bannerId: string,
): GetHomepageCommercialOperationBannerData => ({
  type: GET_HOMEPAGE_COMMERCIAL_OPERATION_BANNER_DATA,
  coId,
  bannerId,
});

export interface GetHomepageCommercialOperationBannerDataSuccess {
  type: typeof GET_HOMEPAGE_COMMERCIAL_OPERATION_BANNER_DATA_SUCCESS;
  products: Product[];
  commercialOperation: CommercialOperationMetadata;
  bannerId: string;
}

export interface GetHomepageCommercialOperationBannerDataFailure {
  type: typeof GET_HOMEPAGE_COMMERCIAL_OPERATION_BANNER_DATA_FAILURE;
  error: string;
  bannerId: string;
}

export type Action =
  | GetHomepage
  | GetHomepageB2B
  | GetHomepageSuccess
  | GetHomepageFailure
  | GetHomepageCampaigns
  | GetHomepageCampaignsSuccess
  | GetHomepageCampaignsFailure
  | FetchSecondaryPromotions
  | FetchSecondaryPromotionsSuccess
  | FetchSecondaryPromotionsFailure
  | GetHomepageSecondaryCampaigns
  | GetHomepageSecondaryCampaignsSuccess
  | GetHomepageSecondaryCampaignsFailure
  | GetHomepageTertiaryCampaigns
  | GetHomepageTertiaryCampaignsSuccess
  | GetHomepageTertiaryCampaignsFailure
  | GetSuggestedProductsSuccess
  | GetPromotedProductsSuccess
  | GetHomepageCommercialOperationBannerDataSuccess
  | GetHomepageCommercialOperationBannerDataFailure;
