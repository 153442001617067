import classnames from 'classnames';
import type { FunctionComponent, ReactNode } from 'react';

import { TextSkeleton } from '@/core/tamagoshi/TextSkeleton/TextSkeleton';

import styles from './SectionTitle.module.scss';

interface Props {
  title?: ReactNode;
  className?: string;
}

export const SectionTitle: FunctionComponent<Props> = ({
  title,
  className,
}) => (
  <div className={classnames(styles.container, className)}>
    <h2 className={styles.title}>{title}</h2>
  </div>
);

export const SectionTitleSkeleton: FunctionComponent<{
  className?: string;
}> = ({ className }) => (
  <div className={classnames(styles.container, className)}>
    <TextSkeleton className={styles.title} style={{ maxWidth: '200px' }} />
  </div>
);
