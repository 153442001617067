import { translate } from '@/core/localization/translate';
export const {
  selectedProductTitle,
  suggestedProductTitle,
  promotedProductTitle,
  recentlyTitle,
  ariaLabelPrevious,
  ariaLabelNext
} = translate({
  "selectedProductTitle": "Selecci\xF3n hecha a tu medida",
  "suggestedProductTitle": "Nuestros productos estrella",
  "promotedProductTitle": "\xA1Ve a por nuestras ofertas!",
  "recentlyTitle": "Productos vistos recientemente",
  "ariaLabelPrevious": "Previo",
  "ariaLabelNext": "Pr\xF3ximo"
});