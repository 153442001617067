import {
  useEffect,
  useRef,
  type MutableRefObject,
  type RefCallback,
} from 'react';

// https://itnext.io/reusing-the-ref-from-forwardref-with-react-hooks-4ce9df693dd
export function useCombinedRefs<T>(
  ...refs: (RefCallback<T> | MutableRefObject<T> | null)[]
): MutableRefObject<T | null> {
  const targetRef = useRef<T>(null);

  useEffect(() => {
    refs.forEach((ref) => {
      if (!ref) {
        return;
      }

      if (typeof ref === 'function') {
        ref(targetRef.current ?? null);
      } else {
        // eslint-disable-next-line no-param-reassign
        ref.current = targetRef.current as T;
      }
    });
  }, [refs]);

  return targetRef;
}
