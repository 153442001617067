import classnames from 'classnames';

import { MediaCarousel } from '@/core/tamagoshi/MediaCarousel/MediaCarousel';
import { useFlags } from '@/domains/core/flags/flags.hooks';
import { useUserTiming } from '@/domains/core/observability/utils/useUserTiming';
import { HP_B2C_BLACKFRIDAY_UPPER_PROMO } from '@/domains/proB2BAnimation/featureFlags';
import { CarouselCard } from '@/domains/retention/Homepage/components/HomepageCampaigns/CarouselCard/CarouselCard';
import { HomepageSecondaryCampaigns } from '@/domains/retention/Homepage/components/HomepageCampaigns/HomepageSecondaryCampaigns/HomepageSecondaryCampaigns';
import { HomepageTertiaryCampaigns } from '@/domains/retention/Homepage/components/HomepageCampaigns/HomepageTertiaryCampaigns/HomepageTertiaryCampaigns';
import { useHomepageMain } from '@/domains/retention/Homepage/hooks/useHomepageMain';

import styles from './HomepageCampaigns.module.scss';

export const HomepageCampaigns = () => {
  useUserTiming('homepageB2C/HomepageCampaigns');

  const sliderElements = useHomepageMain();

  const [isHPB2CBlackfridayUpperPromo] = useFlags([
    HP_B2C_BLACKFRIDAY_UPPER_PROMO,
  ]);

  return (
    <section
      className={classnames(
        styles.campaignsContainer,
        isHPB2CBlackfridayUpperPromo && styles.withoutPaddingBottom,
      )}
    >
      <div className={styles.campaigns}>
        {sliderElements && sliderElements.length !== 0 && (
          <MediaCarousel className={styles.carousel}>
            {sliderElements?.map((slide, index) => (
              <CarouselCard
                element={slide}
                key={`promotion-${slide.name}`}
                loading={index === 0 ? 'eager' : 'lazy'}
                index={index}
              />
            ))}
          </MediaCarousel>
        )}
        <HomepageTertiaryCampaigns
          className={styles.tertiaryCampaigns}
          loading="eager"
        />
      </div>
      <HomepageSecondaryCampaigns />
    </section>
  );
};

HomepageCampaigns.displayName = 'HomepageCampaigns';
