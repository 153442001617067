import { routes } from '@/domains/core/routing/routes/routes.default';
import { createURL } from '@/domains/core/routing/URLParser';
import { getCurrency } from '@/domains/core/settings/utils';
import { generateUrl } from '@/domains/productDiscovery/algolia/components/AlgoliaProductCard/AlgoliaProductCard.utils';
import type { Product } from '@/domains/productDiscovery/algolia/interfaces/product';
import { calculateCouponAmount } from '@/domains/productDiscovery/CommercialAnimation/utils/calculateCouponAmount';
import { articlePathToSlugId } from '@/domains/productDiscovery/ProductPage/utils/productUrl/articlePathToSlugId';
import type { ProductModel } from '@/domains/retention/Homepage/b2c/interfaces/state.model';
import { getPromoTags } from '@/productDiscovery/CommercialAnimation/utils/getProductPromoTags';

export const convertAlgoliaProductModelToHomepageProductModel = (
  algoliaProduct: Product,
): ProductModel => {
  const promoTags = getPromoTags(algoliaProduct);

  const couponAmount = calculateCouponAmount(algoliaProduct.coupon);

  return {
    productId: parseInt(algoliaProduct.productId, 10),
    articleId: algoliaProduct.articleId,
    modelId: algoliaProduct.modelId,
    modelCount: algoliaProduct.modelsCount,
    title: algoliaProduct.title,
    path: createURL(
      routes.product,
      articlePathToSlugId(generateUrl(algoliaProduct)),
    ),
    imageUrl: algoliaProduct.imageFullpath || '',
    indexable: algoliaProduct.indexable,
    brand: {
      id: algoliaProduct.brandId,
      name: algoliaProduct.brandName,
      imageUrl: algoliaProduct.brandImageFullpath,
    },
    rating: {
      average: algoliaProduct.rating,
      count: algoliaProduct.ratingCount,
    },
    price: {
      priceWithVat: algoliaProduct.prices.main || 0,
      ...(algoliaProduct.prices.retail && {
        retailPriceWithVat: algoliaProduct.prices.retail,
      }),
      currency: getCurrency(),
    },
    ...(algoliaProduct.isCompetitive && {
      isCompetitive: algoliaProduct.isCompetitive,
    }),
    ...(couponAmount && { couponAmount }),
    promoTags,
    has1dayDelivery: algoliaProduct.has1dayDelivery,
    hasFreeDelivery: algoliaProduct.hasFreeDelivery,
    isMmf: algoliaProduct.isMmf,
  };
};
