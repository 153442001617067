import type { DocumentInput } from 'urql';

import { queryData } from '@/core/graphqlClient/queryClient';
import logger from '@/core/observability/logger';
import { getPlatform, isB2B } from '@/core/settings/utils';
import { Channel, PartnerAdSpace, Platform } from '@/generated/graphql/types';
import { PartnerAdBannerCampaign } from '@/sellerAdsManagement/PartnerAdBannerCampaign/interfaces/partnerAdBannerCampaign';

import {
  PartnerAdCampaignsBrandBlockDocument,
  PartnerAdCampaignsPromoBlockDocument,
  PartnerAdCampaignsRectangularBlockDocument,
} from './graphql/PartnerAdCampaignsBySpace/generated/partnerAdCampaignsBySpace.query.generated';

export const fetchPartnerAdCampaignsByContext = (
  space: PartnerAdSpace,
  sessionId: string,
  queryDocument: DocumentInput,
) => {
  return queryData<PartnerAdBannerCampaign[]>(queryDocument, {
    platform: getPlatform().toUpperCase() as Platform,
    market: isB2B() ? 'B2B' : 'B2C',
    channel: Channel.WEB,
    space,
    sessionId,
  })
    .then(
      (result: any) =>
        (result.partnerAdCampaigns?.listByContext ??
          []) as PartnerAdBannerCampaign[],
    )
    .catch((e) => {
      logger.error(e.message);
      return [] as PartnerAdBannerCampaign[];
    });
};

export const fetchPartnerAdCampaignsPromoBlock = (sessionId: string) =>
  fetchPartnerAdCampaignsByContext(
    PartnerAdSpace.PROMO_BLOCK,
    sessionId,
    PartnerAdCampaignsPromoBlockDocument,
  );
export const fetchPartnerAdCampaignsBrandBlock = (sessionId: string) =>
  fetchPartnerAdCampaignsByContext(
    PartnerAdSpace.BRAND_BLOCK,
    sessionId,
    PartnerAdCampaignsBrandBlockDocument,
  );
export const fetchPartnerAdCampaignsRectangularBlock = (sessionId: string) =>
  fetchPartnerAdCampaignsByContext(
    PartnerAdSpace.RECTANGULAR_BLOCK,
    sessionId,
    PartnerAdCampaignsRectangularBlockDocument,
  );
