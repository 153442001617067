import { all, call, put, select, takeLatest } from 'typed-redux-saga';

import { waitForAndGetFlagValuesSaga } from '@/core/flags/sagas/waitForAndGetFlagValues.saga';
import { selectVisitorId } from '@/core/tracking/selectors/selectVisitorId';
import type {
  BannerCampaign,
  UserSegments,
} from '@/domains/productDiscovery/CommercialAnimation/interfaces/bannerCampaign';
import { bannerCampaignGqlProcessor } from '@/domains/productDiscovery/CommercialAnimation/services/processor';
import { abTestAndFeatureFlagValidated } from '@/domains/productDiscovery/CommercialAnimation/utils/abTestAndFeatureFlagValidated';
import { getUserSegments } from '@/domains/productDiscovery/CommercialAnimation/utils/getUserSegments';
import { fetchPartnerAdCampaignsBrandBlock } from '@/domains/sellerAdsManagement/PartnerAdBannerCampaign/services/fetchPartnerAdCampaigns';
import { PartnerAdSpace } from '@/generated/graphql/types';
import { fetchHomepageSecondaryCampaignsGql } from '@/retention/Homepage/services/fetchHomepageSecondaryCampaigns';
import { ENABLE_PARTNER_AD_LISTING_CAMPAIGNS } from '@/sellerAdsManagement/PartnerAdBannerCampaign/constants';
import { mapPartnerAdBannerCampaignToBannerCampaign } from '@/sellerAdsManagement/PartnerAdBannerCampaign/services/mapper';

import {
  GET_HOMEPAGE_SECONDARY_CAMPAIGNS,
  GET_HOMEPAGE_SECONDARY_CAMPAIGNS_FAILURE,
  GET_HOMEPAGE_SECONDARY_CAMPAIGNS_SUCCESS,
} from '../Homepage.actions';

export function* getHomepageSecondarySaga(): Generator<any, any, any> {
  try {
    const userSegments: UserSegments = yield* call(getUserSegments);
    const visitorId = yield* select(selectVisitorId);

    const { flags, responseFromApi, partnerAdBanners } = yield* all({
      flags: call(waitForAndGetFlagValuesSaga),
      responseFromApi: call(fetchHomepageSecondaryCampaignsGql, userSegments),
      partnerAdBanners: call(fetchPartnerAdCampaignsBrandBlock, visitorId),
    });

    const fetchPartnerAdCampaignsEnabled = Boolean(
      flags[ENABLE_PARTNER_AD_LISTING_CAMPAIGNS],
    );

    const processedHomepageSecondaryCampaigns =
      responseFromApi.length > 0
        ? responseFromApi
            .filter((campaign: BannerCampaign) =>
              abTestAndFeatureFlagValidated(campaign, flags),
            )
            .map(bannerCampaignGqlProcessor)
        : null;

    let brandBlockBannerCampaigns: BannerCampaign[] = [];

    if (fetchPartnerAdCampaignsEnabled && partnerAdBanners.length > 0) {
      brandBlockBannerCampaigns = mapPartnerAdBannerCampaignToBannerCampaign(
        partnerAdBanners,
        PartnerAdSpace.BRAND_BLOCK,
      );
    }

    yield* put({
      type: GET_HOMEPAGE_SECONDARY_CAMPAIGNS_SUCCESS,
      data: brandBlockBannerCampaigns.concat(
        processedHomepageSecondaryCampaigns,
      ),
    });
  } catch (error) {
    yield* put({
      type: GET_HOMEPAGE_SECONDARY_CAMPAIGNS_FAILURE,
      error: error.response?.data?.error?.app_code || error.message,
    });
  }
}

export function* rootSaga(): Generator {
  yield* takeLatest(GET_HOMEPAGE_SECONDARY_CAMPAIGNS, getHomepageSecondarySaga);
}
