import { PartnerAdSpace } from '@/generated/graphql/types';
import { BannerCampaign } from '@/productDiscovery/CommercialAnimation/interfaces/bannerCampaign';
import { PartnerAdBannerCampaign } from '@/sellerAdsManagement/PartnerAdBannerCampaign/interfaces/partnerAdBannerCampaign';
import { createPalLink } from '@/sellerAdsManagement/PartnerAdLandingPage/utils/createPalLink';

export const mapPartnerAdBannerCampaignToBannerCampaign = (
  partnerAdBannerCampaigns: PartnerAdBannerCampaign[],
  space: PartnerAdSpace,
): BannerCampaign[] => {
  function getPageType(partnerAdCampaign: PartnerAdBannerCampaign) {
    switch (partnerAdCampaign.pageType) {
      case 'brandStore':
        return 'BRAND_STORE';
      case 'commercialOperation':
        return 'COMMERCIAL_OPERATION';
      default:
        return partnerAdCampaign.pageType || '';
    }
  }

  return partnerAdBannerCampaigns.map((partnerAdCampaign) => ({
    id: partnerAdCampaign.id,
    name: partnerAdCampaign.name,
    ...(partnerAdCampaign.title && { title: partnerAdCampaign.title }),
    ...(partnerAdCampaign.subtitle && { subtitle: partnerAdCampaign.subtitle }),
    logoUrl: partnerAdCampaign.logoUrl,
    ...(partnerAdCampaign.label && {
      label: {
        line1: partnerAdCampaign.label.line1 || '',
        line2: partnerAdCampaign.label.line2 || '',
        format: partnerAdCampaign.label.format,
      },
    }),
    link: {
      url:
        !partnerAdCampaign.linkUrl || partnerAdCampaign.linkUrl === ''
          ? createPalLink(
              partnerAdCampaign.sellerAccountId,
              partnerAdCampaign.productIds,
            )
          : partnerAdCampaign.linkUrl,
      target: '_self',
    },
    image: {
      desktopUrl: partnerAdCampaign.image.desktopUrl,
      mobileUrl: partnerAdCampaign.image.mobileUrl,
      altText: partnerAdCampaign.image.altText,
    },
    isPartnerAd: true,
    productIds: partnerAdCampaign.productIds,
    pageType: getPageType(partnerAdCampaign),
    space,
    trackingId: partnerAdCampaign.trackingId,
    sellerAccountId: partnerAdCampaign.sellerAccountId,
  }));
};
