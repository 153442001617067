import classnames from 'classnames';
import {
  forwardRef,
  useEffect,
  useState,
  type ElementType,
  type HTMLProps,
  type ReactNode,
} from 'react';

import { useCombinedRefs } from '../hooks/useCombinedRefs';
import { useIsomorphicLayoutEffect } from '../hooks/useIsomorphicLayoutEffect';
import { Spinner } from '../Spinner/Spinner';

import styles from './Button.module.scss';

const LOADING_DELAY = 150;

export interface ButtonProps<Element extends HTMLElement = HTMLButtonElement>
  extends HTMLProps<Element> {
  'data-testid'?: string;
  /**
   * Defines icon.
   */
  icon?: ReactNode;
  /**
   * Defines icon position.
   */
  iconPosition?: 'left' | 'right';
  /**
   * Disables the Button, preventing mouse events,
   * even if the underlying component is an `<a>` element
   */
  isDisabled?: boolean;
  /**
   * Spans the full width of the Button parent
   */
  isFullWidth?: boolean;
  /**
   * Defines whether the button is in loading mode or not.
   */
  isLoading?: boolean;
  /**
   * Defines loading label associated to the spinner when isLoading sets to `true`.
   */
  loadingLabel?: string;
  /**
   * Defines the shape of the button.
   */
  shape?: 'rectangular' | 'round';
  /**
   * To define the tag to be rendered as any HTML tag
   */
  tag?: ElementType;
  /**
   * @deprecated use data-testid instead
   *
   * Identifier for test purpose.
   * Will add a `data-testid` attribute on the root element.
   */
  testId?: string;
  /**
   * Buttons can have a variety of visual themes.
   */
  theme?: 'default' | 'none' | 'pro' | 'proMarketing';
  /**
   * Buttons can have a variety of visual variants.
   */
  variant?: 'none' | 'primary' | 'secondary' | 'tertiary';
  /**
   * Defines whether the button should be a single line or multiline.
   */
  wordWrap?: boolean;
}

/**
 * Buttons allow users to take actions, and make choices, with a single tap.
 *
 * Buttons style are build on theme and variant props
 *
 * You can learn more about this at [usage section](/docs/documentation-usage--page)
 *
 * @deprecated use tamagoshiV2 Button
 * */
export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      children,
      className,
      icon,
      iconPosition = 'left',
      isDisabled = false,
      isFullWidth = false,
      isLoading = false,
      loadingLabel,
      shape = 'rectangular',
      tag,
      testId,
      theme = 'default',
      type = 'button',
      variant = 'primary',
      wordWrap = true,
      ...restProps
    },
    ref,
  ) => {
    const [width, setWidth] = useState(0);
    const [height, setHeight] = useState(0);
    const [showSpinner, setShowSpinner] = useState(false);
    const combinedRef = useCombinedRefs<HTMLButtonElement | null>(ref);

    // Capture the dimensions of the button before the loading happens
    // so it doesn’t change size when showing the spinner
    useIsomorphicLayoutEffect(() => {
      if (!isLoading) {
        return;
      }
      const boundingClientRect = combinedRef?.current?.getBoundingClientRect();
      if (boundingClientRect) {
        if (boundingClientRect.width) {
          setWidth(boundingClientRect.width);
        }
        if (boundingClientRect.height) {
          setHeight(boundingClientRect.height);
        }
      }
    }, [combinedRef, isLoading]);

    useEffect(() => {
      if (isLoading) {
        setShowSpinner(true);
      }

      // Show loader a bits longer to avoid loading flash if server respond really fast
      if (!isLoading && showSpinner) {
        const timeout = setTimeout(() => {
          setShowSpinner(false);
        }, LOADING_DELAY);

        return () => {
          clearTimeout(timeout);
        };
      }
    }, [isLoading, showSpinner]);

    const buttonClassName = classnames(className, styles.button, {
      [styles.themeDefault]: theme === 'default',
      [styles.themePro]: theme === 'pro',
      [styles.themeProMarketing]: theme === 'proMarketing',
      [styles.variantPrimary]: variant === 'primary',
      [styles.variantSecondary]: variant === 'secondary',
      [styles.variantTertiary]: variant === 'tertiary',
      [styles.shapeRound]: shape === 'round',
      [styles.fullWidth]: isFullWidth,
      [styles.iconOnly]: !children && !!icon,
      [styles.noWordWrap]: !wordWrap,
    });

    const iconPosClassName =
      iconPosition === 'right' ? styles.iconRight : styles.iconLeft;
    const iconPos = children ? iconPosClassName : '';
    const iconNode = !!icon && (
      <span className={classnames(styles.icon, iconPos)}>{icon}</span>
    );

    const content = (
      <>
        {!!children && iconPosition === 'left' && iconNode}
        {!children && iconNode}
        {children}
        {!!children && iconPosition === 'right' && iconNode}
      </>
    );

    const computedProps: ButtonProps = {
      ref: combinedRef,
      className: buttonClassName,
      'data-testid': testId || restProps['data-testid'],
    };

    if (showSpinner) {
      computedProps.style = { height: `${height}px` };
      if (!isFullWidth) {
        computedProps.style.width = `${width}px`;
      }
    }

    let Tag: ButtonProps<any>['tag'];

    if (restProps.href) {
      Tag = tag || 'a';

      if (isDisabled) {
        computedProps.onClick = (event) => {
          event.preventDefault();
          event.stopPropagation();
        };

        computedProps.onKeyDown = (event) => {
          if (event.key === ' ') {
            computedProps.onClick?.(event as any);
          }
        };

        computedProps.tabIndex = -1;
      }
    } else {
      Tag = tag || 'button';
      computedProps.type = type;
      if (showSpinner || isDisabled) {
        computedProps.disabled = true;
      }
    }

    if (showSpinner || isDisabled) {
      computedProps['aria-disabled'] = true;
    }

    const props = { ...restProps, ...computedProps };
    return (
      <Tag {...props}>
        {showSpinner ? <Spinner label={loadingLabel} /> : content}
      </Tag>
    );
  },
);

Button.displayName = 'Button';
