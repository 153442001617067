import { merchandisingLabels } from '@/core/tamagoshi/MerchandisingTag/interfaces';
import type { ProductModel } from '@/domains/retention/Homepage/b2c/interfaces/state.model';

export const getTrackingData = (
  products: ProductModel[] | undefined,
): { currency: string; hasProductsPromoted: boolean } => {
  const hasProductsPromoted = !!products?.find((product) =>
    Boolean(product.promoTags?.includes(merchandisingLabels.PROMO)),
  );
  return {
    currency:
      products && products.length > 0
        ? products[0].price.currency
        : 'undefined',
    hasProductsPromoted,
  };
};
