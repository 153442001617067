import type { FunctionComponent, PropsWithChildren } from 'react';

import { isB2C } from '@/domains/core/settings/utils';
import { useTrackViewedElement } from '@/domains/core/tracking/hooks/useTrackViewedElement';
import { Gtm } from '@/domains/core/tracking/utils/Gtm';
import type { ProductModel } from '@/retention/Homepage/b2c/interfaces/state.model';
import {
  GTM_DISPLAY_PLAYLIST,
  GTM_DISPLAY_PLAYLIST_WITH_PRODUCTS,
} from '@/retention/Homepage/b2c/modules/Tracking/events/displayPlaylist';
import { getTrackingData } from '@/retention/Homepage/b2c/utils/getTrackingData';

export interface RootSectionProps extends PropsWithChildren {
  className?: string;
  translations?: {
    title: string;
    subtitle?: string;
    ariaLabelPrevious?: string;
    ariaLabelNext?: string;
  };
  tracking?: {
    recommendationEngineName: string;
    blockClicked: string;
    spaceSlug?: string;
    interactionHit?: string;
  };
  products?: ProductModel[];
  dataTestId?: string;
}

export const RootSection: FunctionComponent<RootSectionProps> = ({
  className,
  products,
  dataTestId,
  translations,
  tracking,
  children,
}) => {
  const productsTrackingData = getTrackingData(products);
  const [playlistElt] = useTrackViewedElement(
    () => {
      if (dataTestId === 'recently-viewed-products') {
        Gtm.push(
          GTM_DISPLAY_PLAYLIST(
            tracking?.blockClicked || 'recently viewed products',
            productsTrackingData.hasProductsPromoted,
            productsTrackingData.currency,
          ),
        );
      } else {
        Gtm.push(
          GTM_DISPLAY_PLAYLIST_WITH_PRODUCTS(
            tracking?.blockClicked || 'playlist products',
            productsTrackingData.hasProductsPromoted,
            translations?.title || '',
            isB2C() ? 'homepage b2c' : 'homepage b2B',
            products || [],
            productsTrackingData.currency,
          ),
        );
      }
    },
    { triggerOnce: true },
  );

  return (
    <section ref={playlistElt} className={className}>
      {children}
    </section>
  );
};

RootSection.displayName = 'RootSection';
