import NextLink from 'next/link';
import { createElement, forwardRef, type RefAttributes } from 'react';
import { useSelector } from 'react-redux';

import { isMobileAppUserAgentSelector } from '@/domains/core/client/client.selectors';
import { needsLegacyRouting } from '@/domains/core/routing/URLParser';

type DEFAULT_TAG_TYPE = React.ComponentType<
  Omit<React.ComponentProps<typeof NextLink>, 'href' | 'as'> & {
    href?: string;
  }
>;

type BaseProps<TagType extends React.ElementType> = Omit<
  React.ComponentProps<typeof NextLink>,
  'href' | 'as'
> & {
  href: string;
  isLegacy?: boolean;
  tag?: TagType;
};

type Props<TagType extends React.ElementType> = BaseProps<TagType> &
  Omit<React.ComponentPropsWithoutRef<TagType>, keyof BaseProps<TagType>>;

type LinkConstraints = React.PropsWithChildren<
  React.RefAttributes<HTMLAnchorElement> & { href?: string }
>;

function ForwardedLink<
  TagType extends React.ElementType<LinkConstraints> = DEFAULT_TAG_TYPE,
>(
  {
    children,
    href,
    isLegacy,
    passHref,
    prefetch = false, // We disable automatic prefetching triggered by Next.js
    replace,
    scroll,
    shallow,
    tag,
    ...props
  }: Props<TagType>,
  ref: React.ForwardedRef<HTMLAnchorElement>,
) {
  const isMobileAppWebView = useSelector(isMobileAppUserAgentSelector);
  const useLegacy = isMobileAppWebView || isLegacy || needsLegacyRouting(href);

  if (useLegacy) {
    return createElement(
      tag ?? 'a',
      {
        ...props,
        href,
        ref,
      },
      children,
    );
  }

  return (
    <NextLink
      href={href}
      legacyBehavior={!!tag}
      passHref={!!tag || passHref}
      prefetch={prefetch}
      ref={ref}
      replace={replace}
      scroll={scroll}
      shallow={shallow}
      {...(tag ? {} : props)}
    >
      {tag ? createElement(tag, { ...props, ref, href }, children) : children}
    </NextLink>
  );
}

export const Link = forwardRef(ForwardedLink) as <
  TagType extends React.ElementType<LinkConstraints> = DEFAULT_TAG_TYPE,
>(
  props: Props<TagType> & RefAttributes<HTMLAnchorElement>,
) => JSX.Element;
