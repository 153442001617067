import classnames from 'classnames';
import type { FunctionComponent } from 'react';

import { Image } from '@/core/Image/Image';
import { Link } from '@/core/routing/components/Link';
import { generateBrandPath } from '@/retention/Homepage/b2c/utils/generateBrandPath';

import type { BrandModel } from '../../b2c/interfaces/state.model';

import styles from './BrandsCard.module.scss';

interface Props {
  brand: BrandModel;
  onClick?: () => void;
  className?: string;
  index: number;
  disableBrandLinks?: boolean;
}

export const BrandCard: FunctionComponent<Props> = ({
  brand,
  onClick = () => {},
  className,
  index,
  disableBrandLinks = false,
}) => {
  const { title, slug, brandId, logo } = brand;
  const path = generateBrandPath(slug, brandId);

  return (
    <div className={classnames(styles.brand, className)}>
      {disableBrandLinks ? (
        <Image
          alt={title}
          data-src={logo}
          src={logo}
          className={styles.img}
          width={80}
          height={25}
        />
      ) : (
        <Link className={styles.link} href={path} onClick={onClick}>
          <Image
            alt={title}
            data-src={logo}
            src={logo}
            className={styles.img}
            width={80}
            height={25}
          />
        </Link>
      )}
    </div>
  );
};
