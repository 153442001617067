import { createContext } from 'react';

import type { BrandModel } from '../../b2c/interfaces/state.model';

export interface BrandsSliderTrackingContextValue {
  onClickBrand: (brand: BrandModel) => void;
}

export const defaultValue = {
  onClickBrand: () => {},
};

const BrandsSliderTrackingContext =
  createContext<BrandsSliderTrackingContextValue>(defaultValue);

export const BrandsSliderTrackingProvider =
  BrandsSliderTrackingContext.Provider;
export default BrandsSliderTrackingContext;
