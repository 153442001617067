import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useDynamicSaga } from '@/domains/core/applicationState/hooks/useDynamicSaga';
import { authSelector } from '@/domains/customerManagement/customerManagement.selectors';
import { getHomepageSecondaryCampaigns } from '@/domains/retention/Homepage/Homepage.actions';
import { rootSaga } from '@/retention/Homepage/b2c/HomepageSecondary.saga';
import {
  useFetchedSecondary,
  useHomepageSecondary,
} from '@/retention/Homepage/hooks/useHomepageSecondary';

export const useHomepageSecondaryCampaigns = () => {
  useDynamicSaga('homepageSecondaryCampaigns', rootSaga);
  const secondaryCampaigns = useHomepageSecondary();
  const fetched = useFetchedSecondary();

  const auth = useSelector(authSelector);
  const { hasRetrievedCustomerSegments } = auth;

  const dispatch = useDispatch();

  useEffect(() => {
    if (hasRetrievedCustomerSegments && !secondaryCampaigns && !fetched) {
      dispatch(getHomepageSecondaryCampaigns());
    }
  }, [dispatch, hasRetrievedCustomerSegments, secondaryCampaigns, fetched]);

  return { secondaryCampaigns, fetched };
};
