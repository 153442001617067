import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useDynamicSaga } from '@/domains/core/applicationState/hooks/useDynamicSaga';
import { isB2B } from '@/domains/core/settings/utils';
import { authSelector } from '@/domains/customerManagement/customerManagement.selectors';
import { rootSaga } from '@/domains/retention/Homepage/b2c/HomepageTertiary.saga';
import { getHomepageTertiaryCampaigns } from '@/domains/retention/Homepage/Homepage.actions';
import { useHomepageTertiary } from '@/domains/retention/Homepage/hooks/useHomepageTertiary';

export const useHomepageTertiaryCampaigns = () => {
  useDynamicSaga('homepageTertiaryCampaigns', rootSaga);
  const tertiaryCampaigns = useHomepageTertiary();

  const auth = useSelector(authSelector);
  const { hasRetrievedCustomerSegments } = auth;

  const dispatch = useDispatch();

  useEffect(() => {
    if (hasRetrievedCustomerSegments && !tertiaryCampaigns && !isB2B()) {
      dispatch(getHomepageTertiaryCampaigns());
    }
  }, [dispatch, hasRetrievedCustomerSegments, tertiaryCampaigns]);

  return tertiaryCampaigns;
};
