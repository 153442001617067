import classnames from 'classnames';
import type React from 'react';
import { useContext, type ReactElement } from 'react';

import { Carousel } from '@/core/tamagoshi/Carousel/Carousel';
import { useTrackViewedElement } from '@/core/tracking/hooks/useTrackViewedElement';
import { Gtm } from '@/core/tracking/utils/Gtm';
import {
  ariaLabelNext,
  ariaLabelPrevious,
} from '@/retention/Homepage/b2c/modules/Brands/translations';
import { getScrollByFromWidth } from '@/retention/Homepage/b2c/utils/getScrollByFromWidth';
import { BrandCard } from '@/retention/Homepage/components/BrandCard/BrandCard';
import { GTM_DISPLAY_BRANDS } from '@/retention/Homepage/tracking/events/displayHomepageBrands';

import type { BrandModel } from '../../b2c/interfaces/state.model';
import BrandsSliderTrackingContext, {
  type BrandsSliderTrackingContextValue,
} from './BrandsSliderTrackingContext';

import styles from './BrandsSlider.module.scss';

interface Props {
  title: string | ReactElement<{ className: string }>;
  titleClassName?: string;
  subtitle?: string;
  brands?: BrandModel[] | null;
  disableBrandLinks?: boolean;
  controlsClassName?: string;
  cardsClassName?: string;
}

export const BrandsSlider: React.FC<Props> = ({
  title,
  titleClassName,
  brands,
  disableBrandLinks = false,
  controlsClassName,
  cardsClassName,
}) => {
  const tracking = useContext<BrandsSliderTrackingContextValue>(
    BrandsSliderTrackingContext,
  );
  const [brandsRef] = useTrackViewedElement(
    () => {
      Gtm.push(GTM_DISPLAY_BRANDS());
    },
    { threshold: 0.5, triggerOnce: true },
  );
  if (!brands) return null;

  const scrollBy = getScrollByFromWidth({ itemWidth: 106 });

  return (
    <Carousel
      ref={brandsRef}
      ariaLabelLeft={ariaLabelPrevious}
      ariaLabelRight={ariaLabelNext}
      dataTestId="brand"
      title={title}
      titleClassName={titleClassName}
      className={styles.carousel}
      controlsClassName={classnames(styles.controls, controlsClassName)}
      sliderClassName={styles.slider}
      sliderDirectionOptions={{ scrollBy }}
    >
      {brands.map((brand, index) => (
        <BrandCard
          key={`brand-card-${brand.brandId}`}
          brand={brand}
          onClick={() => tracking.onClickBrand(brand)}
          className={classnames(styles.card, cardsClassName)}
          index={index}
          disableBrandLinks={disableBrandLinks}
        />
      ))}
    </Carousel>
  );
};

BrandsSlider.displayName = 'BrandsSlider';
