import {
  HYDRATE,
  type HydrateAction,
} from '@/domains/core/applicationState/store/hydrateAction';
import {
  initialState,
  type HomepageState,
} from '@/domains/retention/Homepage/b2c/interfaces/state.model';
import type { BannerCampaign } from '@/productDiscovery/CommercialAnimation/interfaces/bannerCampaign';

import type { SpartacuxRetentionState } from '../retention.model';
import {
  FETCH_SECONDARY_PROMOTIONS_SUCCESS,
  GET_HOMEPAGE,
  GET_HOMEPAGE_CAMPAIGNS,
  GET_HOMEPAGE_CAMPAIGNS_FAILURE,
  GET_HOMEPAGE_CAMPAIGNS_SUCCESS,
  GET_HOMEPAGE_COMMERCIAL_OPERATION_BANNER_DATA_FAILURE,
  GET_HOMEPAGE_COMMERCIAL_OPERATION_BANNER_DATA_SUCCESS,
  GET_HOMEPAGE_FAILURE,
  GET_HOMEPAGE_SECONDARY_CAMPAIGNS_SUCCESS,
  GET_HOMEPAGE_SUCCESS,
  GET_HOMEPAGE_TERTIARY_CAMPAIGNS_SUCCESS,
  GET_PROMOTED_PRODUCTS_SUCCESS,
  GET_SUGGESTED_PRODUCTS_SUCCESS,
  type Action,
} from './Homepage.actions';

export default function homepageReducer(
  state: HomepageState = initialState,
  action: Action | HydrateAction<SpartacuxRetentionState>,
): HomepageState {
  switch (action.type) {
    case HYDRATE:
      return {
        ...state,
        ...action.state.retention?.homepage,
      };

    case GET_HOMEPAGE:
      return {
        ...state,
        fetching: true,
      };

    case GET_HOMEPAGE_SUCCESS:
      return {
        ...state,
        data: action.data,
        fetching: false,
      };

    case GET_HOMEPAGE_FAILURE:
      return {
        ...state,
        error: action.error,
        fetching: false,
      };

    case GET_HOMEPAGE_CAMPAIGNS:
      return {
        ...state,
        fetching: true,
      };

    case GET_HOMEPAGE_CAMPAIGNS_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          campaigns: action.data,
        },
        fetching: false,
      };

    case GET_HOMEPAGE_SECONDARY_CAMPAIGNS_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          campaigns: {
            homepageMain: state.data?.campaigns?.homepageMain
              ? (state.data?.campaigns?.homepageMain as BannerCampaign[])
              : null,
            homepageSecondary: action.data as BannerCampaign[],
            homepageSuggestedCategory: state.data?.campaigns
              ?.homepageSuggestedCategory
              ? (state.data?.campaigns
                  ?.homepageSuggestedCategory as BannerCampaign[])
              : null,
            homepageTertiary: state.data?.campaigns?.homepageTertiary
              ? (state.data?.campaigns?.homepageTertiary as BannerCampaign[])
              : null,
            homepageCommercialOperation: state.data?.campaigns
              ?.homepageCommercialOperation
              ? (state.data?.campaigns
                  ?.homepageCommercialOperation as BannerCampaign[])
              : null,
            secondaryFetched: true,
          },
        },
        fetching: false,
      };

    case GET_HOMEPAGE_TERTIARY_CAMPAIGNS_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          campaigns: {
            homepageMain: state.data?.campaigns?.homepageMain
              ? (state.data?.campaigns?.homepageMain as BannerCampaign[])
              : null,
            homepageSecondary: state.data?.campaigns?.homepageSecondary
              ? (state.data?.campaigns?.homepageSecondary as BannerCampaign[])
              : null,
            homepageSuggestedCategory: state.data?.campaigns
              ?.homepageSuggestedCategory
              ? (state.data?.campaigns
                  ?.homepageSuggestedCategory as BannerCampaign[])
              : null,
            homepageTertiary: action.data as BannerCampaign[],
            homepageCommercialOperation: state.data?.campaigns
              ?.homepageCommercialOperation
              ? (state.data?.campaigns
                  ?.homepageCommercialOperation as BannerCampaign[])
              : null,
          },
        },
        fetching: false,
      };

    case GET_HOMEPAGE_CAMPAIGNS_FAILURE:
      return {
        ...state,
        error: action.error,
        fetching: false,
      };

    case GET_SUGGESTED_PRODUCTS_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          suggestedProducts: action.data,
        },
      };

    case GET_PROMOTED_PRODUCTS_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          promotedProducts: action.data,
        },
      };

    case FETCH_SECONDARY_PROMOTIONS_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          secondaryPromotions: action.data,
        },
      };

    case GET_HOMEPAGE_COMMERCIAL_OPERATION_BANNER_DATA_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          commercialOperationBanners: {
            ...state.data?.commercialOperationBanners,
            [action.bannerId]: {
              products: action.products,
              commercialOperation: action.commercialOperation,
            },
          },
        },
      };

    case GET_HOMEPAGE_COMMERCIAL_OPERATION_BANNER_DATA_FAILURE:
      return {
        ...state,
        data: {
          ...state.data,
          commercialOperationBanners: {
            ...state.data?.commercialOperationBanners,
            [action.bannerId]: {
              error: action.error,
            },
          },
        },
      };

    default:
      return state;
  }
}
