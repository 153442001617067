import { useSelector } from 'react-redux';

import type { SpartacuxRetentionState } from '../../retention.model';

export const homepageSecondarySelector = (state: SpartacuxRetentionState) =>
  state.retention?.homepage?.data?.campaigns?.homepageSecondary;

export const fetchedSecondarySelector = (state: SpartacuxRetentionState) =>
  state.retention?.homepage?.data?.campaigns?.secondaryFetched || false;

export const useHomepageSecondary = () =>
  useSelector(homepageSecondarySelector);

export const useFetchedSecondary = () => useSelector(fetchedSecondarySelector);
