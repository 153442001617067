import type { Currency } from '@/domains/core/localization/interfaces/Currency';
import type { Product } from '@/domains/productDiscovery/algolia/interfaces/product';
import type { Uri } from '@/domains/retention/Homepage/b2c/interfaces/generic.model';
import type { HomepageCampaignsModel } from '@/domains/retention/Homepage/interfaces/homepageCampaigns.model';
import { BannerCampaign } from '@/productDiscovery/CommercialAnimation/interfaces/bannerCampaign';
import type { PromoTags } from '@/productDiscovery/CommercialAnimation/interfaces/tags';
import type { CommercialOperationMetadata } from '@/productDiscovery/Listings/commercialOperation/model';

export const initialState: HomepageState = {
  fetching: false,
};

export interface HomepageServerModel {
  suggestedProducts?: ProductModel[];
  advice?: AdviceModel[];
  guides?: GuideModel[];
  brands?: BrandModel[];
  promotedProducts?: ProductModel[];
  campaigns?: HomepageCampaignsModel;
  secondaryPromotions?: BannerCampaign[];
  commercialOperationBanners?: CommercialOperationBanners;
}

export interface CommercialOperationBanners {
  [key: string]: CommercialOperationBanner;
}

export interface CommercialOperationBanner {
  products?: Product[];
  commercialOperation?: CommercialOperationMetadata;
  error?: string;
}

export interface PromotionImage {
  mobileUrl: Uri;
  desktopUrl: Uri;
  altText: string;
}

export interface BrandModel {
  brandId: number;
  id?: string;
  logo?: string;
  slug: string;
  title: string;
}

export interface AdviceModel {
  title: string;
  url: Uri;
  image: AdviceImage;
}

export interface AdviceImage {
  url: Uri;
  altText: string;
}

export interface GuideModel {
  title: string;
  url: string;
  image: { url: Uri; altText: string };
}

export interface ProductBrand {
  id: number;
  name: string;
  imageUrl: Uri;
}

export interface ProductRating {
  average: number;
  count: number;
}

export interface ProductPrice {
  priceWithVat: number;
  retailPriceWithVat?: number | null;
  currency: Currency;
}

export interface ProductModel {
  productId: number;
  articleId: number;
  modelId: number;
  modelCount: number;
  title: string;
  path: Uri;
  imageUrl: Uri;
  indexable: boolean;
  brand: ProductBrand;
  rating: ProductRating;
  price: ProductPrice;
  isCompetitive?: boolean | null;
  couponAmount?: string | null;
  promoTags?: PromoTags[];
  sponsor?: string;
  advertiser?: string;
  has1dayDelivery: boolean;
  hasFreeDelivery: boolean;
  isMmf: boolean;
}

export interface SeoModel {
  familyId: number;
  categoryName: string;
  categoryPath: Uri;
  items: SeoItem[];
}

export interface SeoItem {
  title: string;
  url: Uri;
}

export interface HomepageState {
  fetching: boolean;
  error?: string;
  data?: HomepageServerModel;
}
