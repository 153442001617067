import { queryData } from '@/domains/core/graphqlClient/queryClient';
import { getPlatform, isB2B } from '@/domains/core/settings/utils';
import type { UserSegments } from '@/domains/productDiscovery/CommercialAnimation/interfaces/bannerCampaign';
import { type Platform } from '@/generated/graphql/types';
import { HomepageSecondaryCampaignsDocument } from '@/productDiscovery/CommercialAnimation/services/graphql/BannerCampaigns/generated/bannerCampaigns.query.generated';

export const fetchHomepageSecondaryCampaignsGql = (
  userSegments?: UserSegments,
) =>
  queryData(HomepageSecondaryCampaignsDocument, {
    platform: getPlatform().toUpperCase() as Platform,
    market: isB2B() ? 'B2B' : 'B2C',
    userSegments: userSegments || {},
  }).then((result: any) => result.bannerCampaigns);
