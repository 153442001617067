import classnames from 'classnames';
import type { FunctionComponent } from 'react';

import styles from './Spinner.module.scss';

export interface SpinnerProps {
  /**
   * For accessibility purpose, it's always a good thing to provide some context to the spinner
   */
  label?: string;
  className?: string;
  size?: 'sm' | 'md' | 'lg' | 'auto';
  /**
   * For automated test purpose
   */
  testId?: string;
  /**
   * Can be use for loading progression
   */
  valueMin?: number;
  /**
   * Can be use for loading progression
   */
  valueMax?: number;
  /**
   * Can be use for loading progression
   */
  valueNow?: number;
}

/**
 * @deprecated use Tamagoshi V2 Spinner
 */
export const Spinner: FunctionComponent<SpinnerProps> = ({
  label,
  className,
  size = 'md',
  testId,
  valueMin,
  valueMax,
  valueNow,
}) => {
  const spinnerClassName = classnames(className, styles.spinner, {
    [styles.spinnerSm]: size === 'sm',
    [styles.spinnerMd]: size === 'md',
    [styles.spinnerLg]: size === 'lg',
    [styles.spinnerAuto]: size === 'auto',
  });

  return (
    <div
      className={spinnerClassName}
      data-testid={testId}
      role="progressbar"
      aria-valuemin={valueMin}
      aria-valuemax={valueMax}
      aria-valuenow={valueNow}
      aria-valuetext={label}
    >
      <svg
        viewBox="0 0 100 100"
        xmlns="http://www.w3.org/2000/svg"
        focusable="false"
      >
        <circle cx="50" cy="50" r="45" />
      </svg>
    </div>
  );
};

Spinner.displayName = 'Spinner';
