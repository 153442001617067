import classnames from 'classnames';
import { memo, type FunctionComponent } from 'react';

import { useUserTiming } from '@/core/observability/utils/useUserTiming';
import { isB2C } from '@/core/settings/utils';
import { Carousel } from '@/core/tamagoshi/Carousel/Carousel';
import { ProductCardSkeleton } from '@/core/tamagoshi/ProductCardSkeleton/ProductCardSkeleton';
import {
  SectionTitle,
  SectionTitleSkeleton,
} from '@/retention/Homepage/b2c/components/SectionTitle/SectionTitle';
import type { ProductModel } from '@/retention/Homepage/b2c/interfaces/state.model';
import {
  ariaLabelNext,
  ariaLabelPrevious,
} from '@/retention/Homepage/b2c/modules/ListingProducts/translations';
import { getScrollByFromWidth } from '@/retention/Homepage/b2c/utils/getScrollByFromWidth';
import { HomepageProductCard } from '@/retention/Homepage/components/HomepageProductCard/HomepageProductCard';

import {
  RootSection,
  RootSectionProps,
} from './components/RootSection/RootSection';

import styles from './listingProducts.module.scss';

export interface ListingProductsProps
  extends Omit<RootSectionProps, 'className'> {
  loading?: 'eager' | 'lazy';
  className?: string;
}

export const ListingProducts: FunctionComponent<ListingProductsProps> = ({
  dataTestId = '',
  loading = 'lazy',
  products,
  translations,
  tracking,
  className,
}) => {
  useUserTiming(`homepageB2C/listingProducts/${dataTestId}`);

  const scrollBy = getScrollByFromWidth({ itemWidth: 226 });

  return (
    <RootSection className={classnames(styles.root, styles.section, className)}>
      <Carousel
        ariaLabelLeft={translations?.ariaLabelPrevious || ariaLabelPrevious}
        ariaLabelRight={translations?.ariaLabelNext || ariaLabelNext}
        dataTestId={dataTestId}
        title={
          translations ? (
            <SectionTitle title={translations?.title} />
          ) : (
            <SectionTitleSkeleton />
          )
        }
        titleClassName={styles.carouselTitle}
        className={styles.carousel}
        controlsClassName={styles.carouselControls}
        sliderClassName={styles.carouselSlider}
        sliderDirectionOptions={{ scrollBy }}
      >
        {!products || products.length === 0
          ? [1, 2, 3, 4, 5, 6].map((key) => (
              <ProductCardSkeleton
                key={key}
                vertical
                light
                className={styles.carouselCard}
              />
            ))
          : products.map((product: ProductModel, position: number) => {
              const props = {
                position,
                product,
                className: styles.carouselCard,
                recommendationEngineName: tracking?.recommendationEngineName,
                eventTrigger: isB2C() ? 'homepage b2c' : 'homepage b2B',
                blockClicked: tracking?.blockClicked,
                listType: dataTestId,
                spaceSlug: tracking?.spaceSlug,
                interactionHit: tracking?.interactionHit,
              };

              return position < 5 ? (
                <HomepageProductCard
                  loading={loading}
                  {...props}
                  key={product.articleId || product.modelId || position}
                />
              ) : (
                <HomepageProductCard
                  {...props}
                  key={product.articleId || product.modelId || position}
                />
              );
            })}
      </Carousel>
    </RootSection>
  );
};

export const ListingProductsMemo: FunctionComponent<ListingProductsProps> =
  memo(
    ListingProducts,
    (prevProps, nextProps) =>
      !(
        prevProps.dataTestId !== nextProps.dataTestId ||
        prevProps.products?.length !== nextProps.products?.length
      ),
  );

ListingProducts.displayName = 'ListingProducts';
